import origCode from "@lekoarts/gatsby-theme-minimal-blog/src/styles/code"

const code = {
    ...origCode,
    ".gatsby-highlight": {
        ...(origCode[".gatsby-highlight"]),
        "pre[class~='language-sql']:before": {
            content: `'sql'`,
            background: `#61dafb`,
            color: `black`,
        },
    }
}

export default code